import { COMPARE_USC_SETTINGS_INIT, CompareUscSettingsActionsType } from "../actions/CompareUscSettingsActions";
import { UscEnv } from "../../../shared-logic/utils/uscUtils";
import { compareUSCLabels, CompareUSCLabelType } from "../../utils/labels";
import { UsedCarCompareDisclaimerType } from "../../utils/disclaimerUtils";
import { DfEquipmentCategoryCode } from "../../../shared-logic/utils/dfConstants";
import { CompareUscSpecSection } from "../../utils/constants";
import { UscAspectRatioType, UscObjectFitType } from "../../../shared-logic/types/UscCommonTypes";

export type CompareUscSettingsType = {
    initialized: boolean;
    uscEnv: UscEnv;
    compareV2Standalone: boolean;
    urlUsedCarsDetailPage: string;
    urlUsedCarsContactPage: string;
    localContactDealerFormUrl: string;
    enableContactDealerForm: boolean; // AEM only
    enableFinance: boolean;
    usedFootnoteDisclaimers: UsedCarCompareDisclaimerType[];
    disabledEquipmentCategories: DfEquipmentCategoryCode[];
    enabledSpecSections: CompareUscSpecSection[];
    hidePrintButton: boolean;
    aspectRatio: UscAspectRatioType;
    objectFit: UscObjectFitType;
    financeLabelsUseBoldText: boolean;
    labels: Record<CompareUSCLabelType, string>;
};

export const compareUscSettingsInitialState: CompareUscSettingsType = {
    initialized: false,
    uscEnv: UscEnv.Production,
    compareV2Standalone: false,
    urlUsedCarsDetailPage: "",
    urlUsedCarsContactPage: "",
    localContactDealerFormUrl: "",
    enableContactDealerForm: true,
    // The finance will be enabled when the enableFinance setting + the FM enableFinance is set to true
    // The enableFinance setting is currently mostly used for enabling the finance easier on the author environment
    // We set this setting default to true, so the default behaviour is that the FM determines whether the finance is shown or not
    enableFinance: true,
    usedFootnoteDisclaimers: [],
    disabledEquipmentCategories: [], // This setting is undocumented as it is a hacky way to fix 'dirty' data. It also involves manually entering DF codes so not user-friendly as well.
    enabledSpecSections: [
        CompareUscSpecSection.Dimensions,
        CompareUscSpecSection.Consumption,
        CompareUscSpecSection.Engine,
        CompareUscSpecSection.Performance,
        CompareUscSpecSection.Transmission,
    ],
    hidePrintButton: false,
    aspectRatio: "16:9",
    objectFit: "cover",
    financeLabelsUseBoldText: false,
    labels: { ...compareUSCLabels },
};

const CompareUscSettingsReducer = (
    state: CompareUscSettingsType = compareUscSettingsInitialState,
    action: CompareUscSettingsActionsType,
): CompareUscSettingsType => {
    switch (action.type) {
        case COMPARE_USC_SETTINGS_INIT: {
            return { ...state, ...action.initState, initialized: true };
        }

        default:
            return state;
    }
};

export default CompareUscSettingsReducer;
