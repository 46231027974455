import { CompareV2Mode } from "../../../shared-logic/features/compare/utils/types";
import { DfEquipmentCategoryCode } from "../../shared-logic/utils/dfConstants";
import { UscEcoType } from "../../shared-logic/utils/uscEcoUtils";
import { CocaDapPriceType } from "../../../common-deprecated/features/compare/utils/newCarCompareTypes";

export enum UsedSpecCategoryCode {
    Dimension = "DIMCHAS",
    Consumption = "CONSUM",
    Engine = "ENGI",
    Performance = "PERF",
    Transmission = "TRANS",
}

export type PostalUsedDataType = {
    id: string;
    brand: string;
    model: string;
    mode: CompareV2Mode.Used;
};
export type UsedParsedEquipment = {
    code: DfEquipmentCategoryCode;
    name: string;
    items: EquipmentSpecNameIdType[];
};

export type EquipmentSpecNameIdValueType = {
    value: string | number;
    type?: string | number | null;
    price?: CocaDapPriceType;
    colours?: string[];
    equipment?: EquipmentSpecNameIdType[];
    eco?: UscEcoType;
};
export type EquipmentSpecNameIdType = {
    name: string;
    id: number | string;
    code: string;
    index: number;
    unit?: string;
    values?: EquipmentSpecNameIdValueType[];
    allValuesAreTheSame?: boolean;
    description?: string;
    price?: CocaDapPriceType;
};
