import React, { useEffect, useState } from "react";
import { useHasScrolled } from "../../hooks";
import { getTizenVersion } from "../../../shared-logic/features/retailer/utils/utils";
import Icon from "../../styles/v2/toyota/globals/Icon";
import IconWrapper from "../../styles/v2/toyota/globals/IconWrapper";
import { Gradient, Indicator, SmoothScrollStyle } from "./styles/ScrollIndicator";

type ScrollIndicatorProps = {
    viewportRef?: React.RefObject<HTMLDivElement>; // by default checks window scroll position
    bottomOffset?: number;
    recheckKey?: string; // can be used to recheck scroll position in case the content initializes asynchronously clientside (pass initialized flag, step enum...)
};

const ScrollIndicatorDown = ({ viewportRef, bottomOffset, recheckKey }: ScrollIndicatorProps): JSX.Element | null => {
    const hasScrolled = useHasScrolled(viewportRef);
    const [canScroll, setCanScroll] = useState(false);

    useEffect(() => {
        const checkCanScroll = (): void => {
            const contentHeight = (viewportRef?.current || window.document.body).scrollHeight;
            setCanScroll(contentHeight > window.innerHeight);
        };

        checkCanScroll();
        window.addEventListener("resize", checkCanScroll);
        return () => window.removeEventListener("resize", checkCanScroll);
    }, [viewportRef, recheckKey]);

    const scrollDown = (): void => {
        if (hasScrolled) return;

        if (viewportRef?.current) {
            viewportRef.current.scrollTo({
                top: viewportRef.current.scrollTop + viewportRef.current.clientHeight / 2,
                behavior: "smooth",
            });
        } else {
            const top = window.scrollY + (2 * window.innerHeight) / 3;
            try {
                window.scrollTo({
                    top,
                    behavior: "smooth",
                });
            } catch (ignored) {
                // For retailer screens specifically: using the behavior flag on Tizen 4 throws an error.
                // Instead we do this and add the CSS rule "scroll-behaviour:smooth" on the viewport.
                window.scroll(0, top);
            }
        }
    };

    const tizenVerion = getTizenVersion();

    return (
        <>
            {tizenVerion && tizenVerion < 5 && <SmoothScrollStyle />}
            <Indicator
                show={!hasScrolled && canScroll}
                onClick={scrollDown}
                onTouchEnd={scrollDown}
                bottomOffset={bottomOffset}
            >
                <IconWrapper size="md">
                    <Icon variant="arrowDown" />
                </IconWrapper>
            </Indicator>
            <Gradient show={!hasScrolled && canScroll} bottomOffset={bottomOffset && bottomOffset - 20} />
        </>
    );
};

export default ScrollIndicatorDown;
